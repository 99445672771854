// src/components/Help.jsx
import React from "react";
import "./Help.css";
import { motion } from "framer-motion";

const Help = () => {
  return (
    <section className="help-section" id="help">
      <motion.div
        className="help-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h2>How to get started?</h2>
        <div className="help-content">
          <div className="help-step">
            <h3>1. Install a wallet</h3>
            <p>
              To interact with the Solana blockchain, you need a wallet. We
              recommend installing <strong>Phantom</strong> or{" "}
              <strong>MetaMask</strong>.
            </p>
            <ul className="wallet-list">
              <li>
                <span className="no-wrap">
                  <strong>Visit</strong>
                </span>{" "}
                <img
                    src="/assets/phantom.svg"
                    alt="Phantom Icon"
                    className="wallet-icon"
                  />
                <a
                  href="https://phantom.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Phantom.app
                </a>
              </li>
              <li>
                <span className="no-wrap">
                  <strong>Visit</strong>
                </span>{" "}
                <img
                    src="/assets/metamask.png"
                    alt="MetaMask Icon"
                    className="wallet-icon"
                  />
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MetaMask.io
                </a>
              </li>
            </ul>
          </div>
          <div className="help-step">
            <h3>2. Fund your wallet with SOL</h3>
            <p>
              You'll need SOL to pay for transactions on the Solana network.
            </p>
            <ul className="funding-list">
              <li>
                <span className="no-wrap">
                  <strong>1. Purchase</strong>{" "}
                  <img
                    src="/assets/solana.png"
                    alt="SOL Icon"
                    className="step-icon"
                  />{" "}
                  SOL on a cryptocurrency exchange like
                  <img
                    src="/assets/coinbase.png"
                    alt="Coinbase Icon"
                    className="step-icon"
                  />{" "}
                  Coinbase,
                  <img
                    src="/assets/binance.png"
                    alt="Binance Icon"
                    className="step-icon"
                  />
                  Binance, or{" "}
                  <img
                    src="/assets/kraken.webp"
                    alt="Kraken Icon"
                    className="step-icon"
                  />
                  Kraken.
                </span>
              </li>
              <li>
                <span className="no-wrap">
                  <strong>2. Transfer</strong>{" "}
                  <img
                    src="/assets/solana.png"
                    alt="SOL Icon"
                    className="step-icon"
                  />{" "}
                </span>
                SOL to your{" "}
                <img
                  src="/assets/phantom.svg"
                  alt="Phantom Icon"
                  className="step-icon"
                />
                Phantom or{" "}
                <img
                  src="/assets/metamask.png"
                  alt="MetaMask Icon"
                  className="step-icon"
                />{" "}
                MetaMask wallet.
              </li>
            </ul>
          </div>
          <div className="help-step">
            <h3>3. Buy $JTC tokens</h3>
            <p>
              Once your wallet is funded, you can buy $JTC tokens on Raydium.
            </p>
            <ul className="buy-list">
              <li>
                <span className="no-wrap">
                  <strong>1. Connect</strong>
                </span>{" "}
                &nbsp;your wallet to
                <a
                  href="https://raydium.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raydium.io
                </a>
              </li>
              <li>
                <span className="no-wrap">
                  <strong>2. Navigate</strong>
                </span>{" "}
                to the "Swap" section.
              </li>
              <li>
                <span className="no-wrap">
                  <strong>3. Select</strong>{" "}
                  <img
                    src="/assets/dog2.png"
                    alt="$JTC Icon"
                    className="step-icon"
                  />
                  $JTC and{" "}
                  <img
                    src="/assets/solana.png"
                    alt="SOL Icon"
                    className="step-icon"
                  />
                  SOL as your token pair.
                </span>
              </li>
              <li>
                <span className="no-wrap">
                  <strong>
                    4. Enter the amount and confirm the transaction to receive
                  </strong>
                  <img
                    src="/assets/dog2.png"
                    alt="$JTC Icon"
                    className="step-icon"
                  />{" "}
                  $JTC tokens.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Help;
