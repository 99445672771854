import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Merch.css";
import { FaPaypal } from "react-icons/fa6";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="arrow-right" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="arrow-left" />
    </div>
  );
};

const Merch = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const items = [
    {
      id: 1,
      image: "assets/item1.png",
      name: "Item 1",
      description: "T-shirt 'Doggy' / S, M, L, XL",
      price: "$25",
      type: "paypal", // Добавлен тип
    },
    {
      id: 2,
      image: "assets/item4.jpg",
      name: "Item 2",
      description: "T-shirt '$JTC Limited' / S, M, L, XL",
      price: "5M $JTC",
      type: "crypto", // Добавлен тип
    },
    {
      id: 3,
      image: "assets/item3.png",
      name: "Item 3",
      description: "T-shirt '$JTC-2'/ S, M, L, XL",
      price: "$25",
      type: "paypal", // Добавлен тип
    },
    {
      id: 4,
      image: "assets/item2.png",
      name: "Item 4",
      description: "Hoodie 'Doggy' / S, M, L, XL",
      price: "$25",
      type: "paypal", // Добавлен тип
    },
    {
      id: 5,
      image: "assets/item3.png",
      name: "Item 5",
      description: "T-shirt '$JTC' / S, M, L, XL",
      price: "3M $JTC",
      type: "crypto", // Добавлен тип
    },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (selectedImage) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [selectedImage]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div id="merch" className="merch-container">
      <h2>$JTC Merch</h2>
      <div className="dropAt">drop on January 25, 2025</div>
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id} className="merch-item">
            <div className="image-wrapper">
              <img
                src={item.image}
                alt={item.name}
                className="merch-image"
                onClick={() => openModal(item.image)}
                draggable="false"
              />
            </div>
            <div className="merch-description">{item.description}</div>
            <button className="buy-button1">
              {item.price}{" "}
              {item.type === "paypal" && <FaPaypal className="paypal" />}
            </button>
          </div>
        ))}
      </Slider>
      <div
        className={`modal-overlay ${
          selectedImage ? "modal-open" : "modal-closed"
        }`}
        onClick={closeModal}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Selected"
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </div>
  );
};

export default Merch;
