// Main.js
import React, { useEffect, useState } from "react";
import styles from "./Main.module.css";
import { motion } from "framer-motion";
import TokenCard from "./TokenCard";
import {
  faCoins,
  faDollarSign,
  faWallet,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Main = () => {
  const [price, setPrice] = useState(null);
  const [error, setError] = useState(null);

  const tokenMintAddress = "79u3BkmLPW63ANQRkaoygTs2rUxRVTszkVrEeT9rKUiG";
  const solMintAddress = "So11111111111111111111111111111111111111112";

  function formatSmallNumber(number) {
    const numberStr = number.toString();
    if (numberStr.startsWith("0.")) {
      const leadingZeros = numberStr.match(/0\.(0*)/)[1].length;
      const significantDigits = numberStr.slice(leadingZeros + 2);
      return `0.0₇${leadingZeros}${significantDigits}`;
    }
    return number;
  }

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.raydium.io/v2/ammV3/ammPools"
        );

        if (!response.data || !Array.isArray(response.data.data)) {
          throw new Error("Invalid response format from API.");
        }

        const pools = response.data.data;

        const targetPool = pools.find((pool) => {
          const baseMint = pool.baseMint
            ? pool.baseMint.toLowerCase()
            : pool.mintA
            ? pool.mintA.toLowerCase()
            : null;
          const quoteMint = pool.quoteMint
            ? pool.quoteMint.toLowerCase()
            : pool.mintB
            ? pool.mintB.toLowerCase()
            : null;

          if (!baseMint || !quoteMint) return false;

          return (
            (baseMint === tokenMintAddress.toLowerCase() &&
              quoteMint === solMintAddress.toLowerCase()) ||
            (quoteMint === tokenMintAddress.toLowerCase() &&
              baseMint === solMintAddress.toLowerCase())
          );
        });

        if (targetPool) {
          const rawPrice = targetPool.price;
          const decimalsA = targetPool.mintDecimalsA || 0;
          const decimalsB = targetPool.mintDecimalsB || 0;

          if (rawPrice && rawPrice > 0) {
            const calculatedPrice =
              1 / (rawPrice / 10 ** (decimalsB - decimalsA));
            const formattedPrice = formatSmallNumber(
              calculatedPrice.toFixed(18).slice(0, -4)
            );
            setPrice(formattedPrice);
            setError(null);
          } else {
            setPrice(null);
            setError("Price value is invalid or missing.");
          }
        } else {
          setPrice(null);
          setError("Token pair not found.");
        }
      } catch (err) {
        setPrice(null);
        setError("Error fetching price.");
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000);
    return () => clearInterval(interval);
  }, [tokenMintAddress]);

  const contractAddress = "79u3BkmLPW63ANQRkaoygTs2rUxRVTszkVrEeT9rKUiG";
  const shortenedAddress = "79u3...KUiG";
  const solscanLink = `https://solscan.io/address/${contractAddress}`;

  const textElements = Array.from({ length: 20 }, (_, index) => (
    <span
      key={index}
      className={styles.circleText}
      style={{ "--index": index }}
    >
      $JTC
    </span>
  ));

  return (
    <main className={styles.main} id="home">
      <motion.div
        className={styles.mainContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.leftSection}>
          <motion.h1
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 50 }}
          >
            Jittercoin / $JTC
          </motion.h1>
          <motion.p
            className={styles.descriptionText2}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Dogs are now the pinnacle of meme culture. Everything else is just
            trying to catch up. Join the movement with $JTC!
          </motion.p>
          <div className={styles.reelsContainer}>
            <video
              className={styles.reel}
              src="assets/video1.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
            <video
              className={styles.reel}
              src="assets/video2.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
            <video
              className={styles.reel}
              src="assets/video3.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
          </div>
          <div className={styles.seeMoreWrapper}>
            <a
              href="https://www.instagram.com/jittercore?igsh=Mm44MXdmdnYyb2Np"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.seeMoreLink}
            >
              <span className={styles.seeMoreText}>See more</span>
              <span className={styles.arrow}>➤</span>
              <img
                src="assets/inst.webp"
                alt="Instagram Icon"
                className={styles.instIcon}
              />
            </a>
          </div>
        </div>
        <div className={styles.rightSection}>
          <motion.div
            className={styles.rotatingHead}
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              duration: 20,
              ease: "linear",
            }}
          >
            <img
              src="/assets/dog.png"
              alt="Rotating Head"
              className={styles.headImage}
            />
          </motion.div>
          <motion.div
            className={styles.rotatingText}
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              repeat: Infinity,
              duration: 25,
              ease: "linear",
            }}
          >
            {textElements}
          </motion.div>
        </div>
      </motion.div>
      <motion.div
        className={styles.tokenData}
        id="about"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <h2>About</h2>
        <div className={styles.tokenCardsContainer}>
          <TokenCard
            title="Total Supply"
            value="69B"
            description="The total supply of $JTC tokens in circulation."
            icon={faCoins}
            color="black"
          />
          <TokenCard
            title="Price per $JTC"
            value={
              price ? (
                `${price} SOL`
              ) : error ? (
                error
              ) : (
                <div className={styles.spinner}></div>
              )
            }
            description="Current price of $JTC on Solana."
            icon={faDollarSign}
            color="black"
          />
          <TokenCard
            title="Token Address"
            value={
              <a
                href={solscanLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.contractLink}
              >
                {shortenedAddress}
              </a>
            }
            description="The unique address of the $JTC token on the blockchain."
            icon={faWallet}
            color="black"
          />
          <TokenCard
            title="Liquidity Pool"
            value="Raydium"
            description="Liquidity provided on Raydium for trading $JTC."
            icon={faWater}
            color="black"
          />
        </div>
      </motion.div>
    </main>
  );
};

export default Main;
