// src/components/Loading.jsx
import React from "react";
import { motion } from "framer-motion";
import "./Loading.css";

function Loading() {
  const textElements = Array.from({ length: 12 }, (_, index) => (
    <span key={index} className="circle-text" style={{ "--index": index }}>
      $JTC
    </span>
  ));

  return (
    <div className="Loading">
      <motion.div
        className="rotating-head"
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{
          repeat: Infinity,
          duration: 20,
          ease: "linear",
        }}
      >
        <img
          src="/assets/dog2.png"
          alt="Rotating Head"
          className="head-image"
        />
      </motion.div>
      <motion.div
        className="rotating-text"
        initial={{ rotate: 0 }}
        animate={{ rotate: -360 }}
        transition={{
          repeat: Infinity,
          duration: 25,
          ease: "linear",
        }}
      >
        {textElements}
      </motion.div>
    </div>
  );
}

export default Loading;
