// src/components/Header.jsx
import React from "react";
import "./Header.css";
import { motion } from "framer-motion";
import BuyButton from "./BuyButton";

const Header = () => {
  // Функция плавного скролла
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    const headerHeight = document.querySelector(".header").offsetHeight;

    if (target) {
      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
  };

  return (
    <header className="header">
      <motion.div
        className="header-container"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      >
        <div className="logo-section">
          <img src="/assets/dog.png" alt="Jitter Logo" className="logo" />
          <a
            href="#home"
            className="logo-text"
            onClick={(e) => handleScroll(e, "home")}
          >
            jittercore{" "}
          </a>
        </div>
        <nav className="nav-menu">
          <a href="#about" onClick={(e) => handleScroll(e, "about")}>
            About
          </a>
          <a href="#help" onClick={(e) => handleScroll(e, "help")}>
            How to Buy
          </a>
          <a href="#editing" onClick={(e) => handleScroll(e, "editing")}>
            Editing
          </a>
          <a href="#merch" onClick={(e) => handleScroll(e, "merch")}>
            Merch
          </a>
          <a href="#help" onClick={(e) => handleScroll(e, "donations")}>
            Donations
          </a>
          <a
            href="https://www.instagram.com/jittercore?igsh=Mm44MXdmdnYyb2Np"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img
              src="/assets/inst.webp"
              alt="Instagram"
              className="social-icon2"
            />
            Instagram
          </a>
          <a
            href="https://youtube.com/@jittercore?si=QrLsJG0akEC8gsAp"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <img
              src="/assets/youtube.webp"
              alt="YouTube"
              className="social-icon"
            />
            YouTube
          </a>
        </nav>
        <BuyButton />
      </motion.div>
    </header>
  );
};

export default Header;
