// src/components/Footer.jsx
import React from "react";
import { FaYoutube, FaInstagram, FaCopy } from "react-icons/fa";
import "./Footer.css";
import { motion } from "framer-motion";
import { toast } from "sonner";

const Footer = () => {
  const cryptoAddresses = [
    {
      name: "Ethereum",
      icon: "/assets/ethereum.png",
      address: "0x8E441dac8EB44DDF8FC59A5aCB84954Fd0Ece61d",
    },
    {
      name: "USDT",
      icon: "/assets/usdt.png",
      address: "0xD1EDF70F0a7fB040B5E7Af9195765c69227Be8b9",
      network: "ERC20",
    },
    {
      name: "Solana",
      icon: "/assets/solana.png",
      address: "A13s5tbHCB4Nzq4ct5wUTzfYf8nxbAaStmdyGgFKC4to",
    },
    {
      name: "Bitcoin",
      icon: "/assets/bitcoin.png",
      address: "bc1qjfuqwwjmraue3uxrwvrr6q5mlkxmsh7078rt79",
    },
    {
      name: "Polygon",
      icon: "/assets/polygon.png",
      address: "0xCf58506a859c502E126c7728A550c1868325e842",
    },
  ];

  const copyToClipboard = (address) => {
    const shortenAddress = (addr) => `${addr.slice(0, 4)}...${addr.slice(-4)}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(address).then(
        () => {
          toast.success(`Copied! ${shortenAddress(address)}`);
        },
        () => {
          toast.error("Failed to copy address. Please try again.");
        }
      );
    } else {
      // Резервный метод для iOS и старых браузеров
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success(`Copied ${shortenAddress(address)}!`);
      } catch (err) {
        toast.error("Failed to copy address. Please try again.");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <footer className="footer" id="about">
      <div className="footer-container">
        <div className="social-icons">
          <motion.a
            href="https://youtube.com/@jittercore?si=QrLsJG0akEC8gsAp"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaYoutube />
          </motion.a>
          <motion.a
            href="https://www.instagram.com/jittercore?igsh=Mm44MXdmdnYyb2Np"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaInstagram />
          </motion.a>
          <motion.a
            href="https://raydium.io/swap/?inputMint=sol&outputMint=79u3BkmLPW63ANQRkaoygTs2rUxRVTszkVrEeT9rKUiG"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <img
              src="/assets/raydium.png"
              alt="Jitter Logo"
              className="logoRad"
            />
          </motion.a>
        </div>

        <div className="footer-logo-contact">
          <a href="mailto:coop@jittercore.art" className="contact-email">
            coop@jittercore.art
          </a>
          <div className="footer-logo">
            <img
              src="assets/dog.png"
              alt="Jitter Logo"
              className="footer-logo-image"
            />
            <span className="name">jittercore</span>
          </div>
        </div>
      </div>

      <div className="crypto-section" id="donations">
        <h2 className="crypto-title">Support us</h2>
        <div className="crypto-addresses">
          {cryptoAddresses.map((crypto) => (
            <div className="crypto-item" key={crypto.name}>
              <img
                src={crypto.icon}
                alt={`${crypto.name} Icon`}
                className="crypto-icon"
              />
              <span className="crypto-name">
                {crypto.name}
                {crypto.network && (
                  <span className="network-badge">{crypto.network}</span>
                )}
              </span>
              <span className="crypto-address">{crypto.address}</span>
              <button
                className="copy-button"
                onClick={() => copyToClipboard(crypto.address)}
              >
                <FaCopy />
              </button>
            </div>
          ))}
        </div>
      </div>

      <motion.div
        className="footer-text"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p className="descfoot">
          $JTC is a meme coin with no intrinsic value or expectation of
          financial return. There is no formal team or roadmap. The coin is
          completely useless and for entertainment purposes only.
        </p>
      </motion.div>
      <motion.div
        className="footer-copyright"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p>© {new Date().getFullYear()} Jittercore. All rights reserved.</p>
      </motion.div>
    </footer>
  );
};

export default Footer;
