import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Help from "./components/Help";
import Loading from "./components/Loading";
import Separator from "./components/Separator";
import Merch from "./components/Merch";
import Editing from "./components/Editing";
import { initGA, logPageView } from "./analytics";
import { Toaster } from "sonner"; // Импортируем Toaster из sonner
import "./App.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initGA();

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Analytics />
      <div className="App">
        <Header />
        <Main />
        <Help />
        <Editing />
        <Separator />
        <Merch />
        <Footer />
        <Toaster
          toastOptions={{
            className: "sonner-toast", // Привязываем класс
          }}
        />
      </div>
    </Router>
  );
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;
