// src/components/BuyButton.jsx
import React from "react";
import { logEvent } from "../analytics";
import "./BuyButton.css";

const BuyButton = () => {
  const handleClick = () => {
    logEvent({
      category: "User",
      action: "Clicked Buy Button",
      label: "Buy $JTC",
    });

    window.open(
      "https://raydium.io/swap/?inputMint=sol&outputMint=79u3BkmLPW63ANQRkaoygTs2rUxRVTszkVrEeT9rKUiG",
      "_blank"
    );
  };

  // Создаём массив из 20 снежинок
  const snowflakeCount = 20;
  const snowflakes = Array.from({ length: snowflakeCount }, (_, i) => (
    <span key={i} className="snowflake" />
  ));

  return (
    <button onClick={handleClick} className="buy-button">
      {snowflakes}
      Buy $JTC
    </button>
  );
};

export default BuyButton;
