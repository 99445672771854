// src/analytics.js
import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-6RN6YSW8W3"); // Ваш Measurement ID
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path, debug_mode: true });
};

export const logEvent = ({ category, action, label, value }) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    debug_mode: true,
  });
};
