// Editing.jsx
import React, { useState, useEffect } from "react";
import { FaCloudUploadAlt, FaPaperPlane, FaCheck } from "react-icons/fa";
import { toast } from "sonner";
import "./Editing.css";

const Editing = () => {
  const [file, setFile] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fieldsFilled = walletAddress.trim() && contactInfo.trim();
    const checkboxesChecked = isAddressConfirmed && isAcknowledged;
    setIsButtonDisabled(!(fieldsFilled && checkboxesChecked));
  }, [walletAddress, contactInfo, isAddressConfirmed, isAcknowledged]);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.size <= 25 * 1024 * 1024) {
      setFile(uploadedFile);
      toast.success("File uploaded successfully!");
    } else {
      toast.error("File size must be less than 25MB.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !walletAddress ||
      !contactInfo ||
      !isAddressConfirmed ||
      !isAcknowledged
    ) {
      toast.error("Please fill in all required fields and check the boxes.");
      return;
    }

    const formData = new FormData();
    formData.append("walletAddress", walletAddress);
    formData.append("contactInfo", contactInfo);
    formData.append("isAddressConfirmed", isAddressConfirmed);
    formData.append("isAcknowledged", isAcknowledged);
    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await fetch("https://jittercore.art/submit-form", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("Your request has been sent!");
        setFile(null);
        setWalletAddress("");
        setContactInfo("");
        setIsAddressConfirmed(false);
        setIsAcknowledged(false);
      } else {
        toast.error("Failed to send your request. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const truncateFileName = (name, maxLength = 20) => {
    if (name.length <= maxLength) return name;
    const ext = name.substring(name.lastIndexOf("."));
    const base = name.substring(0, maxLength - ext.length - 3);
    return `${base}...${ext}`;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    toast.success("Confirmed!");
    setIsAcknowledged(true);
    closeModal();
  };

  return (
    <div className="editing-container" id="editing">
      <h2>Editing for you</h2>
      <p className="description">
        The artist will create a custom video using your video if you are a
        holder of $JTC tokens. Holders of a significant amount of $JTC tokens
        will have their video featured in the artist's Instagram story.
      </p>
      <form className="editing-content" onSubmit={handleSubmit}>
        <div className="file-upload-container">
          <div className="file-upload">
            <label htmlFor="file-input" className="file-upload-label">
              <FaCloudUploadAlt size={30} className="cloudIcon" />
              {file
                ? truncateFileName(file.name)
                : "Click to upload a video file"}
            </label>
            <p className="file-requirements">
              Accepted formats: MP4, MOV, Max size: 25MB, Max duration: 20
              seconds
            </p>
            <input
              id="file-input"
              type="file"
              accept="video/*"
              onChange={handleFileUpload}
            />
          </div>
          {file && (
            <div className="video-preview">
              <video
                src={URL.createObjectURL(file)}
                autoPlay
                loop
                muted
                playsInline
                className="video-element"
              ></video>
              <div className="video-preview-label">Preview</div>
            </div>
          )}
        </div>
        <div className="form-fields">
          <div className="form-group">
            <label htmlFor="wallet-address">
              Enter your Solana wallet address
            </label>
            <input
              id="wallet-address"
              type="text"
              placeholder="Wallet address"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-info">
              Provide your contact information
            </label>
            <input
              id="contact-info"
              type="text"
              placeholder="Telegram, email, etc."
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
            />
          </div>

          <div className="checkbox-group">
            <div className="checkbox-item">
              <input
                type="checkbox"
                id="address-confirm"
                checked={isAddressConfirmed}
                onChange={(e) => setIsAddressConfirmed(e.target.checked)}
              />
              <label htmlFor="address-confirm" className="checkboxText">
                I confirm that I have provided the correct address and contact
                information.
              </label>
            </div>
            <div className="checkbox-item">
              <input
                type="checkbox"
                id="permission-confirm"
                checked={isAcknowledged}
                onChange={(e) => setIsAcknowledged(e.target.checked)}
                disabled={!isAcknowledged}
              />
              <label htmlFor="permission-confirm" className="checkboxText">
                I confirm that I provide permission for the use and modification
                of the content in accordance with the above conditions.
              </label>
              <span className="see-more-editing" onClick={openModal}>
                Conditions
              </span>
              <div></div>
            </div>
          </div>

          <button
            type="submit"
            className="submit-button"
            disabled={isButtonDisabled}
          >
            Send <FaPaperPlane className="polaneIcon" />
          </button>
        </div>
      </form>

      <div
        className={`modal-overlay-editing ${
          isModalOpen ? "modal-open" : "modal-closed"
        }`}
        onClick={closeModal}
      >
        <div
          className="modal-content-editing"
          onClick={(e) => e.stopPropagation()}
        >
          <h3>Consent Form for Content Usage</h3>
          <p className="modal-text">
            <strong>Copyright confirmation:</strong>
          </p>
          <p className="modal-text">
            I confirm that the content I am providing is my own or I have all
            rights to use this content and permit its modification.
          </p>

          <p className="modal-text">
            <strong>Permission for use:</strong>
          </p>
          <p className="modal-text">
            I grant permission for the use and modification of the provided
            content for publication on the Instagram account as part of the meme
            token holders' incentive program.
          </p>

          <p className="modal-text">
            <strong>Responsibility for Third-Party Content:</strong>
          </p>
          <p className="modal-text">
            I confirm that the content I have provided does not infringe upon
            third-party copyrights and take full responsibility for any claims
            related to the use of this content.
          </p>

          <p className="modal-text">
            <strong>Terms of Use:</strong>
          </p>
          <ul className="modal-list">
            <li>
              The content will be used solely for the purposes of the meme token
              holders' incentive program.
            </li>
            <li>
              I understand that the provided content may be altered using AI and
              agree to such modifications.
            </li>
            <li>
              I understand that no additional compensation will be provided for
              the use of the content.
            </li>
            <li>
              I understand that the artist may refuse to create a video for
              ethical or moral reasons.
            </li>
          </ul>

          <button className="confirm-button-editing" onClick={handleConfirm}>
            <FaCheck className="check-icon" /> Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Editing;
