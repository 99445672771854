// TokenCard.js
import React from "react";
import styles from "./TokenCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TokenCard = ({ title, value, description, icon, color }) => {
  return (
    <div className={styles.card} style={{ borderTop: `5px solid ${color}` }}>
      <div className={styles.iconContainer} style={{ backgroundColor: color }}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
      </div>
      <div className={styles.content}>
        <h3>{title}</h3>
        <div className={styles.value}>{value}</div>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default TokenCard;
